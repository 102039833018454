<script setup>
const emit = defineEmits(['onClick'])
const props = defineProps({
  customIcon: {
    type: Boolean,
    default: true
  },
  arrow: {
    type: String,
    default: 'right'
  },
  style: {
    type: Object
  }
})
const onClick = () => {
  emit('onClick')
}
</script>

<template>
  <button
    class="text-button reset-button items-center text-gray6 text-caption2"
    @click="onClick"
    :style="style"
  >
    <span
      class="text-underline"
    >
      <slot />
    </span>
    <slot name="notUnder"/>
    <template v-if="customIcon">
      <IconChevronRight v-if="arrow === 'right'" width="24" height="24" />
      <IconChevronLeft v-if="arrow === 'left'" width="24" height="24"/>
      <IconChevronDown v-if="arrow === 'down'" width="24" height="24"/>
      <IconChevronUp v-if="arrow === 'up'" width="24" height="24"/>
    </template>
  </button>
</template>

<style scoped lang="scss">
.text-button {
  display: inline-flex;
}
</style>
